//import Button from 'react-bootstrap/Button';
import { CartContext } from "../context/CartContext";
import { useContext } from "react";
import { ProductsContext } from "../context/ProductsContext"; 

function CartProduct(props) {
    const cart = useContext(CartContext);
    const { productsArray } = useContext(ProductsContext);
    const id = props.id;
    const quantity = props.quantity;

    // Get product data based on ID from productsArray
    const productData = productsArray.find(product => product._id === id);

    // Handle case where productData might be undefined
    if (!productData) {
        return null; // Or show a loading spinner / message
    }

    return (
        <>
            <div className="row main align-items-center">
                <div className="col-2">
                    <img className="img-fluid" src={productData.images[0].url} alt={productData.name} style={{width : '100%'}}/>
                </div>
                <div className="col-5">
                    <div className="row">
                        <div className='text-muted'>{productData.category}</div>
                        <div>{productData.name}</div>
                    </div>
                    <div className="row"></div>
                </div>
                <div className="col-2">
                    <a onClick={() => cart.removeOneFromCart(id)} className="add-remove-qty">-</a>
                    {quantity}
                    <a onClick={() => cart.addOneToCart(id, productData.inventory)} className="add-remove-qty">+</a>  
                </div>
                <div className="col-3 text-end">
                    {cart.formatPrice(quantity * productData.price, "USD")}
                </div>
                {/*<div className="col-1 text-end">
                    <span className="cart-close" onClick={() => cart.deleteFromCart(id)}>✕</span>
                </div>*/}
            </div>
        </>
    )
}

export default CartProduct;