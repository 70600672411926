// CartModal.js
import { Modal } from 'react-bootstrap';
import CartProduct from './CartProduct';
import { useState ,useContext, useEffect, useMemo } from 'react';
import { CartContext } from '../context/CartContext';
import { ShippingContext } from "../context/ShippingContext";
import { initiateCheckout } from '../services/checkoutService';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { toast } from 'react-toastify';  // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

const CartModal = ({ show, handleClose, storeHandle }) => {
  const cart = useContext(CartContext);
  const { shippingCost, setShippingCost, getShippingRate, availableCountries } = useContext(ShippingContext);
  const [selectedCountry, setSelectedCountry] = useState("US");

  // Memoize product count and subtotal cost calculations to avoid unnecessary re-renders
  const productsCount = useMemo(() => cart.items.reduce((sum, product) => sum + product.quantity, 0), [cart.items]);
  const subTotalCost = useMemo(() => cart.getSubTotalCost().toFixed(2), [cart]);

  useEffect(() => {
    if (selectedCountry) {
      // Set shipping cost based on the selected country using getShippingCost function from CartContext
      const cost = cart.getShippingCost(selectedCountry);
      setShippingCost(cost);
    }
  }, [selectedCountry, cart, setShippingCost]);

  /*
  const checkout = async () => {
    const shippingRate = getShippingRate(selectedCountry, cart.getTotalWeight());

    console.log('storeHandle: ' + storeHandle);
    console.log('checkout shippingRate: ' + JSON.stringify(shippingRate));
    if (shippingRate && shippingRate.rateId) {
      await initiateCheckout(cart, shippingRate.rateId, storeHandle, selectedCountry);
    }
  };
*/

  const checkout = async () => {
    try {
      const shippingRate = getShippingRate(selectedCountry, cart.getTotalWeight());

      console.log('storeHandle: ' + storeHandle);
      console.log('checkout shippingRate: ' + JSON.stringify(shippingRate));

      if (shippingRate && shippingRate.rateId) {
        await initiateCheckout(cart, shippingRate.rateId, storeHandle, selectedCountry);
      } else {
        throw new Error('Shipping rate not found for the selected country.');
      }
    } catch (error) {
      // Show toast notification for error
      toast.error(error.message || 'An error occurred during checkout. Please try again.');
    }
  };

  //const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header style={{padding: '5px'}}>
      <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
    </Modal.Header>
    <Modal.Body>
        <>
            <div className="row">
            {productsCount > 0 ?
                <>
                    <div className="col-md-8 cart">
                        <div className="row">
                            <div className="title">
                                <div className="row">
                                    <div className="col-8">
                                        <h4><b>Shopping Cart</b></h4>
                                    </div>
                                    <div className="col-4 align-self-center text-end text-muted">
                                        {productsCount}  items
                                    </div>
                                </div>
                            </div>
                        </div>
                        {cart.items.map( (currentProduct, idx) => (
                            <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
                        ))}
                    </div>
                    <div className="col-md-4 summary">
                        <div>
                            <h5>
                            <b>Summary</b>
                            </h5>
                        </div>
                        <hr />
                        <div className="row" style={{ paddingBottom: "10px" }}>
                            <div className="col">
                            ITEMS ({productsCount})
                            </div>
                            <div className="col text-end">{ cart.formatPrice(subTotalCost,'USD') }</div>
                        </div>
                        <div className="row" style={{ paddingBottom: "10px" }}>
                            <div className="col">
                            SHIPPING<p><small>{ cart.getTotalWeight().toFixed(2) }lbs</small></p>
                            </div>
                            <div className="col text-end">{selectedCountry && cart.formatPrice(parseFloat(shippingCost || 0), 'USD')}</div>
                        </div>
                        <div
                            className="row"
                            style={{ borderTop: "1px solid rgba(0,0,0,.1)", padding: "10px 0px", marginTop: "20px" }}
                        >
                            <div className="col-6">TOTAL</div>
                            <div className="col-6 text-end">{selectedCountry && cart.formatPrice((parseFloat(subTotalCost || 0) + parseFloat(shippingCost || 0)),'USD') }</div>
                        </div>
                        <div className="row" style={{ padding: "10px 0px", marginTop: "20px", textAlign: "center" }}>
                        <label>where are we shipping?</label>
                        <DropdownButton
                          id="countrySelect"
                          title={selectedCountry ? availableCountries.find(c => c.code === selectedCountry)?.name : 'Select shipment country'}
                          variant="outline-primary"  // Change button style if needed
                          onSelect={(eventKey) => setSelectedCountry(eventKey)}
                        >
                          <Dropdown.Item eventKey="">Select shipment country</Dropdown.Item>
                          {availableCountries.map(country => (
                            <Dropdown.Item key={country.code} eventKey={country.code}>
                              {country.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                        </div>
                        <div
                            className="row"
                            style={{ padding: "10px 0px", marginTop: "0px" }}
                        >
                          <button
                            className={`sunthar-checkout-button ${selectedCountry === '' ? 'disabled' : ''}`} // Conditionally add a 'disabled' class
                            onClick={checkout}
                            disabled={selectedCountry === ''} // Disable button if no country is selected
                            style={{ 
                              opacity: selectedCountry === '' ? 0.5 : 1,  // Reduce opacity if disabled
                              cursor: selectedCountry === '' ? 'not-allowed' : 'pointer'  // Change cursor style
                            }}
                          >
                            PURCHASE
                          </button>
                        </div>
                    </div>
                </>
                :
                <>
                    <div onClick={handleClose} className="col-md-12 cart text-center"  style={{borderRadius:"1rem"}}>
                        <h4>There are no items in your cart!</h4>
                    </div>
                </>
            }
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="back-to-shop">
                  <span onClick={handleClose} className="text-muted">← Back to shop</span>
                </div>
              </div>
            </div>
        </>
    </Modal.Body>
  </Modal>



  );
};

export default CartModal;
