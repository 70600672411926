// ProductDetailsModal.js
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';

const ProductDetailsModal = ({ show, handleClose, product, cart }) => {
  const [mainImage, setMainImage] = useState(product?.images?.[0]?.url || 'default-image-url');
  const thumbnails = product?.images || [];

  useEffect(() => {
    if (product) {
      setMainImage(product.images?.[0]?.url || 'default-image-url');
    }
  }, [product]);

  const scrollThumbnails = (direction) => {
    const container = document.querySelector('.thumbnail-container');
    const scrollAmount = 100; // Adjust based on the thumbnail size
  
    if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>{product?.name}</Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>

      <Modal.Body>
        {product && (
          <>
            {/* Main Image */}
            <div style={{ marginBottom: '15px', width: '100%' }}>
              <img src={mainImage} alt={product.name} className="main-image" />
            </div>

            {/* Scrollable thumbnails below the main image */}
            <div style={{ position: 'relative', width: '100%' }}>
              {/* Scrollable container */}
              <div className="thumbnail-container">
                {thumbnails.map((thumbnail, idx) => (
                  <img
                    key={idx}
                    src={thumbnail.url || 'default-thumbnail'}
                    alt={`Thumbnail ${idx + 1}`}
                    className={`thumbnail-image ${mainImage === thumbnail.url ? 'selected' : ''}`}
                    onClick={() => setMainImage(thumbnail.url)}
                  />
                ))}
              </div>

              {/* Left Arrow Button */}
              <div className="arrow-button arrow-left" onClick={() => scrollThumbnails('left')}>
                ◀
              </div>

              {/* Right Arrow Button */}
              <div className="arrow-button arrow-right" onClick={() => scrollThumbnails('right')}>
                ▶
              </div>
            </div>

            {/* Product Details */}
            <p><strong>Description:</strong> {product.description}</p>
            <p><strong>Price:</strong> {cart.formatPrice(product.price, product.currency)}</p>
            <p><strong>Inventory:</strong> {product.inventory > 0 ? `${product.inventory} in stock` : 'Out of Stock'}</p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <a onClick={handleClose} className="sunthar-close-button">Close</a>
        {product && product.inventory > 0 ? (
          <a onClick={() => cart.addOneToCart(product.id, product.inventory)} className="sunthar-add-button">Add to Cart</a>
        ) : (
          <a className="sunthar-waitlist-button" style={{ pointerEvents: 'none', color: 'gray' }}>Out of Stock</a>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetailsModal;
