import React, { useContext, useState } from 'react';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useAllProducts } from '../productsStore';
import { CartContext } from '../CartContext';
import ProductCard from '../components/ProductCard';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Footer from '../components/Footer';

function Store() {
  const { storeName } = useParams(); // Extract userId from the URL
  const productsArray = useAllProducts(storeName);
  const nonAffiliateProducts = productsArray.filter(product => product.type !== 'affiliate');
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const cart = useContext(CartContext);

  const handleImageClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  return (
    <>
      <Container>
          <ToastContainer />
          <div className="store-page-wrapper">
              <div className="mb-4">
                    <a href="/affiliate">Get compatible SCART cables</a>
              </div>
              <Row xs={1} md={3} lg={4} className="g-4">
                  {nonAffiliateProducts.map((product, idx) => (
                      <Col align="center" key={idx}>
                          <ProductCard product={product} onImageClick={handleImageClick} />
                      </Col>
                  ))}
              </Row>

              {/* Product Details Modal */}
              <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header>
                  <Modal.Title>{selectedProduct?.name}</Modal.Title>
                  <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
                </Modal.Header>
                <Modal.Body>
                  {selectedProduct && (
                    <>
                      <img
                        src={selectedProduct.images[0]}
                        alt={selectedProduct.name}
                        style={{ width: '100%', marginBottom: '15px' }}
                      />
                      <p><strong>Description:</strong> {selectedProduct.description}</p>
                      <p><strong>Price:</strong> {cart.formatPrice(selectedProduct.prices[0].unit_amount, selectedProduct.prices[0].currency)}</p>
                      <p><strong>Inventory:</strong> {selectedProduct.inventory > 0 ? `${selectedProduct.inventory} in stock` : 'Out of Stock'}</p>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <a onClick={handleClose} className="sunthar-close-button">Close</a>
                  {selectedProduct && selectedProduct.inventory > 0 ? (
                    <a onClick={() => cart.addOneToCart(selectedProduct.id, selectedProduct.inventory)} className="sunthar-add-button">Add to Cart</a>
                  ) : (
                    <a className="sunthar-waitlist-button" style={{ pointerEvents: 'none', color: 'gray' }}>Out of Stock</a>
                  )}
                </Modal.Footer>
              </Modal>

              <div className="hint-container warning">
                  <p className="hint-container-title">NOTE</p>
                  <p>
                      I ship these parts in very low volumes (less than 10 a month), I don't carry much inventory.
                      If I get your order and I have any in stock, I will ship as soon as possible.
                      Otherwise, shipping can take as much as a month.
                  </p>
                  <p>
                      There are significant risks in modifying a CRT and all risks arising from modifying the CRT are your responsibility.
                      This kit is sold as is. Returns are not accepted once the kit is used.
                  </p>
                  <p>
                      Make sure to send me your CRT model # to see if I can ship any additional components that can make your life easier.
                      Send an email to support@sunthar.com.
                      I do this as a hobby, therefore my responses might be delayed.
                  </p>
                  <p>Once you are done with your mod, you must send me pictures of your mod 😃</p>
                  <p>
                      As a DIY person, I custom built this shopping cart. It's really basic, but secure as hell.
                      https://checkout.sunthar.com is hosted by Stripe. I do not store any of your credit card details.
                      Only your purchase, name and shipping info is passed to me by Stripe, so that I can ship and support the product.

                      Stripe doesn't support PayPal acceptance in Canada and the US. I will be adding an option to pay using PayPal very soon!
                      Stripe offers greater flexibility and more advanced features compared to Paypal.
                      Feel free to reach out if you have any questions!
                  </p>

                  <p>support@sunthar.com</p>

                  <p>
                      <b>REFUNDS/RETURNS POLICY</b>
                  </p>
                  <p>
                      Several of the parts I ship are customized based on your request. Since I don't run a large operation, 
                      a lot of time is invested in packaging and sending the parts. I take best effort to ensure I send you everything, 
                      but slight errors can occur. Given the nature of the customization involved, I cannot accept returns or restock items. 
                      For example, if couple of resistors or diodes are missing or if incorrect resistor value was shipped, you'll have to source them yourself.
                      However, if you are missing a major component that should be part of the kit that is missing. For example, mux board or the ribbon cable, 
                      I will take responsibility of it. 
                  </p>
                  <p>
                      If you cancel the order before shipping, there is usually a 5% fee that is not refunded by Stripe. 
                      Therefore, you will receive your payment minus the 5% fee or $3 whichever is greater. Items already packaged and/or shipped are not eligible for returns or refunds.
                      Thank you for the support and understanding.
                  </p>
                  <p>
                    All packages are insured. Therefore, if the package is damaged or lost due to shipping company's fault, you are covered! 
                  </p>
              </div>
          </div>
      </Container>
      <Footer />
    </>
  );
}

export default Store;
