// Navbar.js
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CartModal from './CartModal';
import { CartContext } from '../context/CartContext';

function NavbarComponent() {
  const cart = useContext(CartContext);
  const [show, setModalShow] = useState(false);
  const modalClose = () => setModalShow(false);
  const modalShow = () => setModalShow(true);

  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

  const { storeHandle = 'sunthar' } = useParams(); // Default to 'sunthar' if no storeHandle provided

  return (
    <>
      <header id="navbar" className="navbar">
        <div className="navbar-start">
          <a href="https://sector.sunthar.com/">
            <img className="nav-logo" src="/logo.png" alt="Store Logo" />
          </a>
          <a href="https://store.sunthar.com/">
            <span className="site-name">Sunthar's Super Store</span>
          </a>
        </div>
        <div className="navbar-end">
          <a className={`sunthar-checkout-button ${productsCount > 0 ? 'active' : ''}`} onClick={modalShow}>
            <i className="bi bi-cart"></i> Shopping Cart ({productsCount} Items)
          </a>
        </div>
      </header>
      <CartModal show={show} handleClose={modalClose} storeHandle={storeHandle}/>
    </>
  );
}

export default NavbarComponent;