import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import NavbarComponent from './components/Navbar';
import Footer from './components/Footer';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Cancel from './pages/Cancel';
import Store from './pages/Store';
import Success from './pages/Success';
import ProductTypePage from './pages/ProductTypePage'; 
import CartProvider from './context/CartContext';
import ProductsProvider from './context/ProductsContext'; 
import ShippingProvider from './context/ShippingContext';

function App() {

  return (
    <ProductsProvider>
      <ShippingProvider>
        <CartProvider>
          <div className="theme-container no-sidebar">
            <NavbarComponent />
            <BrowserRouter>
              <Routes>
                <Route index element={<Store />} />
                <Route path="/:storeHandle" element={<Store />} />
                {/*
                <Route path="/:store/:product" element={<ProductTypePage />} />
                <Route path="/:store/:product/:page" element={<ProductTypePage />} />
                */}
                <Route path="success" element={<Success />} />
                <Route path="cancel" element={<Cancel />} />
              </Routes>
            </BrowserRouter>
            <Footer />
          </div>
        </CartProvider>
      </ShippingProvider>
    </ProductsProvider>
  );
}

export default App;
