// productService.js

// With React you have to prefix the environment variable with REACT_APP_
const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

export const fetchStoreFrontProducts = async (storeHandle) => {
  const url = `${STORE_BACKEND_URL}/stores/public/${storeHandle}/products/storefront`;

  console.log('fetchStoreFrontProducts called with url: ' + url);
  
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch products: ${response.statusText}`);
    }
    const data = await response.json();
    //console.log('Products Fetched: ' + JSON.stringify(data));

    return data; // Assuming data contains products and totalPages
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};


export const fetchProductsForStore = async (storeHandle, page = 1, limit = 10, type = '') => {
  const url = `${STORE_BACKEND_URL}/stores/public/${storeHandle}/products?type=${type}&page=${page}&limit=${limit}`;

  console.log('fetchProductsForStore called with url: ' + url);
  
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch products: ${response.statusText}`);
    }
    const data = await response.json();
    //console.log('Products Fetched: ' + JSON.stringify(data));

    return data; // Assuming data contains products and totalPages
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};
