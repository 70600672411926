// ProductsContext.js
import React, { createContext, useState } from 'react';
import { fetchProductsForStore, fetchStoreFrontProducts } from '../services/productService';

// Create the ProductsContext
export const ProductsContext = createContext();

// ProductsProvider component
const ProductsProvider = ({ children }) => {
  const [productsByTypeArray, setProductsByTypeArray] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1); // For pagination handling

  // Function to load products
  const loadStoreFrontProducts = async (storeHandle) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchStoreFrontProducts(storeHandle);

      console.log('Store front products by type: ', response.products_by_type);

      setProductsByTypeArray(response.products_by_type); // Assuming response contains products array

    // Combine all products into a single array
    // This is required as CartContext.js uses this information to add products to cart.
    const productsArray = Object.values(response.products_by_type).reduce((acc, typeData) => {
      return [...acc, ...typeData.products];
    }, []);

    // Set combined products array in state
    setProductsArray(productsArray);

    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch products
  // Below is different from the StoreFrontProducts as it allows to filter by type and paginate
  const loadAllProducts = async (storeHandle, page = 1, limit = 10, type = '') => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchProductsForStore(storeHandle, page, limit, type);
      setProductsByTypeArray(response.products_by_type); 
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to retrieve product data by passing productsArray
  const getProductData = (id) => {
    if (!productsArray) return undefined;

    const productData = productsArray.find(product => product._id === id);
    if (!productData) {
      console.error(`Product data does not exist for ID: ${id}`);
      return undefined;
    }
    return productData;
  };

  return (
    <ProductsContext.Provider
      value={{
        productsArray,
        productsByTypeArray, // Make sure to use this in the frontend
        loading,
        error,
        totalPages,
        loadStoreFrontProducts,
        loadAllProducts,
        getProductData,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;