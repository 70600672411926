import React, { useContext } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import Footer from '../components/Footer';
import { CartContext } from '../context/CartContext';

function ProductTypePage() {
  const { storeHandle, type, page = 1 } = useParams();
  const { products: productsArray = [], loading, error, totalPages } = useAllProducts(storeHandle, page, 10, type); // Fetch paginated products for the type
  const cart = useContext(CartContext);
  const navigate = useNavigate();

  console.log('Type from ProductTypePage: ' + type);

  const handleNextPage = () => {
    if (parseInt(page) < totalPages) {
      navigate(`/${storeHandle}/${type}/${parseInt(page) + 1}`);
    }
  };

  const handlePreviousPage = () => {
    if (parseInt(page) > 1) {
      navigate(`/${storeHandle}/${type}/${parseInt(page) - 1}`);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Container>
        <div className="store-page-wrapper">
          <h2>Test Products</h2>
          <Row xs={1} md={3} lg={4} className="g-4">
            {productsArray.map((product, idx) => (
              <Col align="center" key={idx}>
                <ProductCard product={product} />
              </Col>
            ))}
          </Row>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <Button onClick={handlePreviousPage} disabled={parseInt(page) === 1}>
              Previous
            </Button>
            <span> Page {page} of {totalPages} </span>
            <Button onClick={handleNextPage} disabled={parseInt(page) === totalPages}>
              Next
            </Button>
          </div>
        </div>
      </Container>

      <Container>
        <Footer />
      </Container>
    </>
  );
}

export default ProductTypePage;
